<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增':'修改'" :close-on-click-modal="false" @closed="cancel" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="90px" v-if="form" >
        
        <el-form-item label="品牌" prop="brandId">
          <quick-select v-model="form.brandId" filterable url="api/brand" placeholder="请选择" style="width:100%" />
        </el-form-item>
        <el-form-item label="系列名称" prop="name">
          <el-input v-model.trim="form.name" :maxlength="25" />
        </el-form-item>
        <el-form-item label="显示顺序" prop="displayNo">
          <el-input-number v-model="form.displayNo" :min="0" :max="9999" :step="1" :precision="0" controls-position="right" style="width:50%" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/shopSeries";
import { mapGetters } from "vuex";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  data() {
    return {
      loading: false,
      dialog: false,
      searching:false,
      form: {
        name: null,
        code: null,
        groupCode: null,
        mainFlag: null,
        changeRate: null,
        erpCode:null,
        entId:null,
      },
      rules: {
        name: [
          { required: "true", message: "系列名称为必填项", trigger: "blur" }
        ],
        brandId: [
          { required: "true", message: "请选择品牌", trigger: "blur" }
        ],
        
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          add(this.form)
            .then(res => {
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          edit(this.form)
            .then(res => {
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.form = form || {
        name: null,
        brandId: null,
        displayNo:99
      };
    }
  }
};
</script>

